<template>
    <div class="text-white">
		<div class="md:hidden mb-10">
			<hr class="border-my-gray border-t"/>
		</div>

        <FooterContent/>
    </div>
</template>

<script>
    import FooterContent from './FooterContent.vue';

    export default {
        components: {
			FooterContent
        }
    }
</script>
