<i18n lang="json5">{
	ru: {
		restore_password: 'Восстановить пароль',
		forgot_password: 'Забыли пароль?',
		forgot_password_tip: 'Забыли свой пароль? Просто сообщите нам свой Email, и мы пришлём вам ссылку для восстановления пароля.',
		registration: 'Регистрация',
		registration_tooltip: 'Вы будете автоматически зарегистрированы после оформления своего первого заказа. Все необходимые данные будут отправлены на Ваш электронный ящик.',
		login: 'Вход в аккаунт',
		sign_in: 'Войти',
		send_link: 'Отправить ссылку',
		link_sent: 'Ссылка отправлена!',
		email: 'Email',
		your_email: 'Ваш E-mail',
		email_required: 'Укажите Email',
		email_invalid: 'Неверный Email',
		password: 'Пароль',
		password_required: 'Укажите пароль',
		password_wrong: 'Неверно указан пароль. Попробуйте еще раз',
		password_min_length: 'Минимальная длина {length} символов',
		not_registered: 'Данный аккаунт не зарегистрирован. Он будет создан автоматически после оформления первого заказа'
	},
	uz: {
		restore_password: 'Parolni tiklash',
		forgot_password: 'Parolni unutdingizmi?',
		forgot_password_tip: 'Parolingizni unutib qoʼydingizmi? Shunchaki bizga oʼz elektron pochtangizni ayting va biz sizga parolingizni tiklash uchun havola yuboramiz.',
		registration: 'Roʼyxatdan oʼtish',
		registration_tooltip: 'Birinchi buyurtmani rasmiylashtirganingizdan keyin siz avtomatik ravishda roʼyxatdan oʼtasiz. Barcha kerakli maʼlumotlar elektron pochtangizga yuboriladi.',
		login: 'Akkauntga kirish',
		sign_in: 'Kirish',
		send_link: 'Havolani yuborish',
		link_sent: 'Havola yuborildi!',
		email: 'Email',
		your_email: 'Sizning E-mail',
		email_required: 'Elektron pochta manzilingizni kiriting',
		email_invalid: 'Elektron pochtangiz manzilingiz xato',
		password: 'Parol',
		password_required: 'Parolni kiriting',
		password_wrong: 'Parol notoʼgʼri. Yana bir bor urinib koʼring',
		password_min_length: 'Parol kamida {length} ta belgidan iborat boʼlishi kerak',
		not_registered: 'Mazkur akkaunt roʼyxatdan oʼtmagan. U birinchi buyurtma berilgandan soʼng avtomatik ravishda yaratiladi.'
	}
}</i18n>

<template>
	<div id="login-form" class="relative login-form">
		<!--Forgot-->
		<template v-if="forgot">
			<h5 class="text-xl font-bold">
				{{ $t('restore_password') }}
			</h5>

			<p class="text-sm text-gray-400 mt-4">
				{{ $t('forgot_password_tip') }}
			</p>

			<p class="mt-4 font-medium text-sm text-green-600" v-if="forgotForm.success">
				{{ $t('link_sent') }}
			</p>

			<form @submit.prevent="onForgotFormSubmit" novalidate ref="form">
				<div class="mt-6">
					<input
						type="email"
						name="email"
						v-model.trim="$v.forgotForm.email.$model"
						:placeholder="$t('email')"
						class="rounded border border-gray-300 shadow-inner w-full"
						:class="{'border-my-red': $v.forgotForm.email.$error && forgotForm.submitted}"
						ref="forgotEmail"
						autocomplete="username"
					/>
					<div v-if="!$v.forgotForm.email.required && forgotForm.submitted" class="text-sm text-my-red">
						{{ $t('email_required') }}
					</div>
					<div v-else-if="!$v.forgotForm.email.email && forgotForm.submitted" class="text-sm text-my-red">
						{{ $t('email_invalid') }}
					</div>
				</div>

				<button
					type="submit"
					class="btn mt-6 py-2.5 text-center block w-full rounded bg-gradient-to-b from-my-blue-from to-my-blue-to text-white font-bold cursor-pointer"
					:class="{loading: forgotForm.processing}"
					:disabled="forgotForm.processing"
				>
					{{ $t('send_link') }}
				</button>

				<div
					v-if="forgotForm.responseError"
					class="mt-6 text-sm text-my-red"
				>
					{{ forgotForm.responseError }}
				</div>

				<div class="mt-6">
					<span class="underline cursor-pointer" @click="forgot = false">
						{{ $t('login') }}
					</span>
				</div>
			</form>
		</template>
		<!--Forgot-->

		<!--Login-->
		<template v-else>
			<h5 class="text-xl font-bold" v-if="!inline">
				{{ $t('login') }}
			</h5>

			<form
				@submit.prevent="onLoginFormSubmit"
				:class="{'lg:flex': inline}"
				novalidate
			>
				<div
					:class="{
						'mt-6': !inline,
						'relative block lg:w-60 2xl:w-80 lg:basis-60 2xl:basis-80': inline
					}"
				>
					<label>
						<span class="inline-block mb-1" v-if="inline">
							{{ $t('your_email') }}<span class="text-my-red">*</span>
						</span>
						<input
							type="email"
							name="email"
							v-model.trim="$v.loginForm.email.$model"
							:placeholder="!inline ? $t('email') : ''"
							class="rounded border border-gray-300 shadow-inner w-full"
							:class="{
								'border-my-red': $v.loginForm.email.$error && loginForm.submitted,
								'lg:h-12 lg:py-2.5': inline
							}"
							ref="loginEmail"
							autocomplete="username"
						/>
					</label>
					<div v-if="!$v.loginForm.email.required && loginForm.submitted" class="text-sm text-my-red">
						{{ $t('email_required') }}
					</div>
					<div v-else-if="!$v.loginForm.email.email && loginForm.submitted" class="text-sm text-my-red">
						{{ $t('email_invalid') }}
					</div>
				</div>

				<div
					:class="{
						'mt-6': !inline,
						'relative lg:ml-8 block mt-4 lg:mt-0 lg:w-60 2xl:w-80 lg:basis-60 2xl:basis-80': inline
					}"
				>
					<div class="relative">
						<label>
							<span class="inline-block mb-1" v-if="inline">
								{{ $t('password') }}<span class="text-my-red">*</span>
							</span>
							<input
								:type="passwordVisible ? 'text' : 'password'"
								name="password"
								v-model.trim="$v.loginForm.password.$model"
								:placeholder="!inline ? $t('password') : ''"
								class="rounded border border-gray-300 shadow-inner pr-8 w-full"
								:class="{
									'border-my-red': $v.loginForm.password.$error && loginForm.submitted,
									'lg:h-12 lg:py-2.5': inline
								}"
								autocomplete="current-password"
							/>
						</label>
						<img
							:src="passwordVisible ? '/design/eye-off.svg' : '/design/eye.svg'"
							class="absolute right-3 w-5 h-5 cursor-pointer"
							:class="inline ? 'bottom-2 lg:bottom-3 mb-0.5' : 'top-3 -mt-px'"
							alt
							@click="passwordVisible = !passwordVisible"
						/>
					</div>
					<div v-if="!$v.loginForm.password.required && loginForm.submitted" class="text-sm text-my-red">
						{{ $t('password_required') }}
					</div>
					<div v-else-if="!$v.loginForm.password.minLength && loginForm.submitted" class="text-sm text-my-red">
						{{ $t('password_min_length', { length: $v.loginForm.password.$params.minLength.min }) }}
					</div>
				</div>

				<div :class="{'flex lg:ml-8 mt-7 items-start flex-wrap grow basis-0': inline}">
					<button
						type="submit"
						class="btn"
						:class="{
							'mt-6 py-2.5 text-center block w-full rounded bg-gradient-to-b from-my-blue-from to-my-blue-to text-white font-bold cursor-pointer': !inline,
							'buy-button border-my-blue py-2 lg:py-2.5 h-11 lg:h-12': inline,
							loading: loginForm.processing
						}"
						:disabled="loginForm.processing"
					>
						{{ $t('sign_in') }}
					</button>

					<div
						v-if="loginForm.responseError"
						class="text-sm text-my-red"
						:class="{
							'mt-6': !inline,
							'order-last basis-full mt-4': inline
						}"
					>
						{{ loginForm.responseError }}
					</div>

					<div
						class="flex justify-between"
						:class="{'mt-6': !inline}"
					>
						<nuxt-link
							class="align-middle ml-8 mt-2.5 lg:mt-3 underline"
							:to="{path: `/${this.$i18n.locale}/forgot-password`}"
							target="_blank"
							v-if="inline"
						>
							{{ $t('forgot_password') }}
						</nuxt-link>
						<span
							class="mr-1 underline cursor-pointer"
							@click="forgot = true"
							v-else
						>
							{{ $t('forgot_password') }}
						</span>
						<span
							class="ml-1 underline cursor-pointer"
							v-tooltip="{
								container: false,
								placement: 'top-end',
								content: $t('registration_tooltip'),
							}"
							v-if="!inline"
						>
							{{ $t('registration') }}
						</span>
					</div>
				</div>
			</form>
		</template>
		<!--Login-->
	</div>
</template>

<script>
import {required, minLength, email} from 'vuelidate/lib/validators'
export default {
	name: 'LoginForm',
	props: {
		show: Boolean,
		autofocus: Boolean,
		inline: Boolean,
		isForgot: Boolean,
	},
	data() {
		return {
			loginForm: {
				email: '',
				password: '',
				processing: false,
				submitted: false,
				responseError: ''
			},
			forgotForm: {
				email: '',
				success: false,
				processing: false,
				submitted: false,
				responseError: ''
			},
			forgot: this.isForgot,
			passwordVisible: false,
		}
	},

	validations: {
		loginForm: {
			email: {
				required,
				email
			},
			password: {
				required,
				minLength: minLength(6),
			}
		},
		forgotForm: {
			email: {
				required,
				email
			},
		}
	},

	mounted() {
		if (this.show && this.autofocus) {
			if (this.forgot) {
				this.$refs.forgotEmail.focus()
			} else {
				this.$refs.loginEmail.focus()
			}
		}
	},

	methods: {
		onLoginFormSubmit() {
			this.loginForm.submitted = true
			this.$v.loginForm.$touch()

			if (!this.$v.loginForm.$invalid) {
				this.login()
			}
		},
		async login() {
			try {
				this.loginForm.processing = true
				await this.$store.dispatch('user/login', {
					email: this.loginForm.email,
					password: this.loginForm.password,
				})

				this.masterGtag()

				if (this.$route.name === 'login') {
					await this.$router.push({name: 'account'})
				} else if (this.$route.name === 'reset-password') {
					await this.$router.push({name: 'home'})
				}

				this.loginForm.processing = false
				this.resetForms()
			} catch (e) {
				this.loginForm.processing = false

				if (e.response?.status === 401) {
					this.loginForm = {...this.loginForm, responseError: this.$t('password_wrong')}
				} else if (e.response?.status === 404) {
					this.loginForm = {...this.loginForm, responseError: this.$t('not_registered')}
				} else if (e.response?.status === 422) {
					this.loginForm = {...this.loginForm, responseError: Object.values(e.response.data.errors).flat().join(' ')}
				}

				if (!this.$axios.isAxiosError(e)) {
					this.$sentry?.captureException(e)
				}
			}
		},
		onForgotFormSubmit() {
			this.forgotForm.submitted = true
			this.$v.forgotForm.$touch()

			if (!this.$v.forgotForm.$invalid) {
				this.requestPasswordResetEmail()
			}
		},
		async requestPasswordResetEmail() {
			try {
				this.forgotForm.processing = true
				this.forgotForm.success = false
				await this.$store.dispatch('user/requestPasswordResetEmail', {
					email: this.forgotForm.email,
					url: window.location.origin + '/reset-password',
				})
				this.forgotForm.processing = false
				this.forgotForm.success = true
				this.resetForms()
			} catch (e) {
				this.forgotForm.processing = false
				if (e.response?.status === 404) {
					this.forgotForm = {...this.forgotForm, responseError: this.$t('not_registered')}
				} else if (e.response?.status === 422) {
					this.forgotForm = {...this.forgotForm, responseError: Object.values(e.response.data.errors).flat().join(' ')}
				}
				if (!this.$axios.isAxiosError(e)) {
					this.$sentry?.captureException(e)
				}
			}
		},
		hideResponseErrorMessages() {
			this.loginForm.responseError = null
			this.forgotForm.responseError = null
		},
		resetForms() {
			this.loginForm.email = ''
			this.loginForm.password = ''
			this.loginForm.responseError = ''
			this.loginForm.submitted = false
			this.forgotForm.password = ''
			this.forgotForm.responseError = ''
			this.forgotForm.submitted = false
			this.passwordVisible = false
			this.$v.$reset()
		},
		masterGtag() {
			if (window.gtag) {
				window.gtag('event', 'login', {method: 'email'})
			}
		},
	},

	watch: {
		show(isShow) {
			if (isShow && !this.user) {
				this.hideResponseErrorMessages()
				this.$nextTick(() => {
					if (this.forgot) {
						this.$refs.forgotEmail.focus()
					} else {
						this.$refs.loginEmail.focus()
					}
				})
			}
		},
		forgot(isForgot) {
			this.$nextTick(() => {
				this.hideResponseErrorMessages()
				if (isForgot && !this.user) {
					this.$refs.forgotEmail.focus()
				} else {
					this.$refs.loginEmail.focus()
				}
			})
		},
		loginForm: {
			handler(newValue, oldValue) {
				if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
					this.hideResponseErrorMessages()
				}
			},
			deep: true
		},
		forgotForm: {
			handler(newValue, oldValue) {
				if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
					this.hideResponseErrorMessages()
				}
			},
			deep: true
		},
	},

	computed: {
		user() {
			return this.$store.getters['user/user']
		},
	}
}
</script>
