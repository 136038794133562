<template>
	<a
		:href="getLink()"
		class="mr-6 font-bold text-white"
	>
		{{ $i18n.locale === 'ru' ? 'Oʼzb' : 'Рус' }}
	</a>
</template>

<script>

export default {
	methods: {
		getLink() {
			const locale = this.$i18n.locale === 'ru' ? 'uz' : 'ru'
			return this.$route.fullPath.replace(/^\/(ru|uz)/, `/${locale}`)
		},
	}
}
</script>

