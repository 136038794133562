<i18n lang="json5">{
	ru: {
		avia_booking: 'Бронирование авиабилетов',
		traveler_insurance: 'Страхование путешественника',
		bookings_and_tickets: 'Мои брони и билеты',
		passengers: 'Пассажиры',
		logout: 'Выйти',
		email: 'Email:',
		phone: 'Тел.:',
		phone2: 'Тел2.:',
		balance: 'Баланс:',
		sum: 'сум',
	},
	uz: {
		avia_booking: 'Aviachiptalarni bronlash',
		traveler_insurance: 'Sayyohni sugʼurtalash',
		bookings_and_tickets: 'Mening akkauntim',
		passengers: 'Yoʼlovchilar',
		logout: 'Chiqish',
		email: 'Email:',
		phone: 'Tel.:',
		phone2: 'Tel2.:',
		balance: 'Balans:',
		sum: 'soʼm',
	}
}</i18n>

<template>
    <div class="bg-white shadow-md absolute top-9 -right-[70px] sm:right-0 2xl:-right-7 mt-4 p-6 w-72 rounded-md text-left z-50" v-show="show">
        <div class="arrow-up absolute -top-2 right-[82px] sm:right-3 2xl:right-10"></div>

		<template v-if="user">
			<div class="relative inline-block pr-6 text-xl font-bold">
				{{ user.name }}
				<button
					type="button"
					class="absolute right-0 top-0"
					@click="$modal.show('userEditModal')"
				>
					<img src="/design/edit.svg" class="inline-block w-5" alt />
				</button>
			</div>
			<div class="text-sm text-my-black">
				{{ $t('email') }} {{ user.email }}
			</div>
			<div class="text-sm text-my-black">
				{{ $t('phone') }} {{ user.tel }}
			</div>
			<div class="text-sm text-my-black" v-if="user.tel2">
				{{ $t('phone2') }} {{ user.tel2 }}
			</div>
			<div class="text-sm text-my-black" v-if="user.corporate">
				{{ $t('balance') }} <span class="font-bold">
					{{ user.deposit
						? Math.floor(user.deposit['UZS'].amount + user.deposit['UZS'].credit).toLocaleString('ru')
						: 0
					}}
				{{ $t('sum') }}</span>
			</div>

			<hr class="my-6"/>

			<ul>
				<li>
					<nuxt-link :to="{name: 'home', query: {tab: 'avia'}}">
						{{ $t('avia_booking') }}
					</nuxt-link>
				</li>
				<li class="mt-4">
					<nuxt-link :to="{name: 'home', query: {tab: 'insurance'}}">
						{{ $t('traveler_insurance') }}
					</nuxt-link>
				</li>
				<li class="mt-4">
					<nuxt-link :to="{name: 'account'}">
						{{ $t('bookings_and_tickets') }}
					</nuxt-link>
				</li>
				<li class="mt-4">
					<nuxt-link :to="{name: 'passengers'}">
						{{ $t('passengers') }}
					</nuxt-link>
				</li>
				<li>
					<hr class="my-6"/>
				</li>
				<li>
					<button
						type="button"
						class="btn"
						:class="{loading: isLoading}"
						:disabled="isLoading"
						@click="logout"
					>
						<img src="/design/logout.svg" class="inline-block w-5 mr-1" :class="{invisible: isLoading}" alt />
						{{ $t('logout') }}
					</button>
				</li>
			</ul>
		</template>

        <LoginForm :show="show" autofocus v-else />

	    <UserEditModal />
    </div>
</template>

<script>
	import LoginForm from '~/components/LoginForm';
	import UserEditModal from '~/components/UserEditModal';

    export default {
		components: {
			LoginForm,
			UserEditModal,
		},
		props: {
			show: Boolean
		},
		data() {
			return {
				isLoading: false,
			}
		},
		methods: {
			async logout() {
				try {
					this.isLoading = true
					await this.$store.dispatch('user/logout')
					this.isLoading = false
				} catch (e) {
					this.isLoading = false
					if (!this.$axios.isAxiosError(e)) {
						this.$sentry?.captureException(e)
					}
				}
			},
		},
		computed: {
			user() {
				return this.$store.getters['user/user']
			},
		}
    }
</script>
