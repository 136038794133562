<i18n lang="json5">{
	'ru': {
		'working_time': 'с 9:00 до 23:00 без выходных'
	},
	'uz': {
		'working_time': 'Soat 9 dan 23:00 gacha, dam olish kunisiz'
	}
}</i18n>

<template>
	<div class="flex items-center justify-between xl:container xl:mx-auto">
		<div class="">
			<nuxt-link :to="{name: 'home'}" class="inline-block align-middle">
				<img
					v-if="isNewYear"
					src="/design/logo-new-year.png"
					class="w-32 sm:w-44 lg:w-52"
					width="923"
					height="156"
					alt=""
				/>
				<img
					v-else
					src="/design/logo-w.png"
					class="w-32 sm:w-44 lg:w-52"
					width="400"
					height="76"
					alt=""
				/>
			</nuxt-link>

			<div class="hidden lg:inline-block ml-6 pl-14 border-l border-my-gray text-white font-bold align-bottom relative">
				<div class="text-xl leading-none"><a href="tel:+998712004800">+998 (71) 200 48 00</a></div>
				<div class="text-xs">{{ $t('working_time') }}</div>
				<img src="/design/phone-call.svg" class="w-5 absolute top-0 left-6" alt>
			</div>
		</div>

		<div class="">
			<div class="flex items-center pl-2">
				<LanguageSwitcher />
				<div class="relative" v-click-outside="onClickOutside">
					<img
						src="/design/user.svg"
						class="h-10 inline-block cursor-pointer"
						width="40"
						height="40"
						@click="showUserBox = !showUserBox"
						alt
					/>
					<HeaderUserBox :show="showUserBox" />
				</div>
				<div class="ml-6">
					<img
						src="/design/hamburger.svg"
						class="inline-block cursor-pointer"
						width="46"
						height="26"
						alt
						@click="$emit('toggleSidebar')"
					/>
				</div>
			</div>

			<div class="hidden lg:inline-block text-white font-bold text-lg lg:ml-10" v-if="false">
				<span class="">Узб</span>
			</div>

			<nuxt-link to="/" class="inline-block ml-7" v-if="false">
				<img src="/design/top-menu.svg" class="w-12" alt />
			</nuxt-link>
		</div>
	</div>
</template>

<script>
    import HeaderUserBox from './HeaderUserBox.vue';
    import LanguageSwitcher from './LanguageSwitcher.vue';

    export default {
        components: {
            HeaderUserBox,
	        LanguageSwitcher
        },
        data() {
            return {
                showUserBox: false,
				isNewYear: this.$store.getters['isNewYear'],
            }
        },
		methods: {
			onClickOutside() {
				this.showUserBox = false

			}
		}
    }
</script>
