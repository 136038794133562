<i18n lang="json5">{
	ru: {
		avia_tickets: 'Авиабилеты',
		covid: 'COVID-19',
		airport_scoreboard: 'Онлайн табло аэропортов Узбекистана',
		insurance: 'Страхование',
		working_time: 'Ежедневно, с 9:00 до 23:00 без выходных',
		technical_support: 'Техническая поддержка',
	},
	uz: {
		avia_tickets: 'Aviachiptalar',
		covid: 'COVID-19',
		airport_scoreboard: 'Oʼzbekiston aeroportlarining onlayn tablosi',
		insurance: 'Sugʼurta',
		working_time: 'Har kuni, dam olish kunlarisiz 9:00 dan 23:00 gacha',
		technical_support: 'Texnik yordam',
	}
}</i18n>

<template>
	<div class="relative z-20">
		<div class="overlay" @click="$emit('toggleSidebar')" />
		<div class="sidebar">
			<div class="flex flex-col w-full h-full">
				<div class="bg-my-black p-4 h-[72px] text-white">
					<div class="flex items-end justify-between mb-4">
						<div>
							<img
								v-if="isNewYear"
								src="/design/logo-new-year.png"
								class="w-44"
								width="923"
								height="156"
								alt=""
							/>
							<img
								v-else
								src="/design/logo-w.png"
								class="w-44"
								width="400"
								height="76"
								alt=""
							/>
						</div>
						<div class="">
							<img
								src="/design/close.svg"
								class="inline-block -mt-2 cursor-pointer"
								width="16"
								height="16"
								alt
								@click="$emit('toggleSidebar')"
							/>
						</div>
					</div>
				</div>
				<div class="flex-grow p-4">
					<div class="text-lg uppercase mb-1">
						<nuxt-link :to="{name: 'home', query: {tab: 'avia'}}">
							{{ $t('avia_tickets') }}
						</nuxt-link>
					</div>
					<div class="mb-2 pl-4">
<!--						<div>-->
<!--							<nuxt-link :to="{name: 'covid'}">-->
<!--								{{ $t('covid') }}-->
<!--							</nuxt-link>-->
<!--						</div>-->
						<div>
							<nuxt-link :to="{name: 'tashkent-airport-scoreboard'}">
								{{ $t('airport_scoreboard') }}
							</nuxt-link>
						</div>
					</div>

					<div class="text-lg uppercase mb-2">
						<nuxt-link :to="{name: 'home', query: {tab: 'insurance'}}">
							{{ $t('insurance') }}
						</nuxt-link>
					</div>

					<div class="text-lg uppercase mb-2" v-if="testServer">
						<nuxt-link :to="{name: 'home', query: {tab: 'transfer'}}">
							Трансфер
						</nuxt-link>
					</div>

					<div class="text-lg uppercase mb-2" v-if="testServer">
						<nuxt-link :to="{name: 'home', query: {tab: 'train'}}">
							Поезда
						</nuxt-link>
					</div>
				</div>
				<div class="bg-my-black p-4 pb-14 text-white">
					<div class="mb-4">
						{{ $t('working_time') }}
					</div>
					<div class="mb-4">
						<a href="tel:+998712004800" target="_blank" class="inline-flex items-center text-lg">
							<span class="flex items-center justify-center w-[35px] h-[35px] rounded-full bg-my-blue mr-2">
								<img src="/design/phone-call-white.svg" class="inline-block w-5 h-auto" width="20" height="20" alt />
							</span>
							<span>
								+998 71 200 48 00
							</span>
						</a>
					</div>
					<div class="mb-4">
						<a href="https://t.me/bookhara_support" target="_blank" class="inline-flex items-center text-lg">
							<span class="mr-2">
								<img src="/design/telegram.svg" class="inline-block w-9 h-auto" width="35" height="35" alt />
							</span>
							<span>
								{{ $t('technical_support') }}
							</span>
						</a>
					</div>
					<div class="mb-4">
						<a href="https://wa.me/998974123331" target="_blank" class="inline-flex items-center text-lg">
							<span class="mr-2">
								<img src="/design/whatsapp.svg" class="inline-block w-9 h-auto" width="35" height="35" alt />
							</span>
							<span>
								+998 97 412 33 31
							</span>
						</a>
					</div>
					<div class="">
						<a href="mailto:info@bookhara.com" target="_blank" class="inline-flex items-center text-lg">
							<span class="flex items-center justify-center w-[35px] h-[35px] rounded-full bg-amber-500 mr-2">
								<img src="/design/email.svg" class="inline-block w-6 h-auto" width="24" height="24" alt />
							</span>
							<span>
								info@bookhara.com
							</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {testServer} from '../../nuxt.config.js'
export default {
	name: 'Sidebar',
	data() {
		return {
			testServer: testServer,
			isNewYear: this.$store.getters['isNewYear'],
		}
	}
}
</script>

<style scoped>
.overlay {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.2);
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.3s, visibility 0.3s;
}
.open .overlay {
	visibility: visible;
	opacity: 1;
}
.sidebar {
	overflow-x: hidden;
	position: fixed;
	right: -25rem;
	top: 0;
	width: 25rem;
	max-width: 100%;
	height: 100%;
	background: #fff;
	visibility: hidden;
	transition: right 0.3s, visibility 0.3s;
}
.open .sidebar {
	right: 0;
	visibility: visible;
}
</style>
